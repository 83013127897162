<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit('close')"
    content-class="pa-5"
  >
    <div class="flex-between">
      <h2 class="text--big mb-4">{{ signUpBonusDetail.name }}</h2>
      <v-btn class="t-btn--red-dark" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
        {{ $t('buttons.close') }}
      </v-btn>
    </div>

    <!-- List Data -->
    <v-row class="ma-0">
      <v-col cols="12" lg="4" md="6" class="pl-lg-0">
        <div class="t-wrapper elevation-3 rounded">
          <div class="t-table">
            <div>
              <label>ステータス</label>
              <strong>
                {{
                  signUpBonusDetail.status === 'Active' ? '対応中' : '対応済'
                }}
              </strong>
            </div>
            <div>
              <label>契約特典期間</label>
              <strong>
                {{
                  (signUpBonusDetail.startDate &&
                    signUpBonusDetail.startDate.split('-').join('/')) +
                    ' ~ ' +
                    (signUpBonusDetail.startDate &&
                      signUpBonusDetail.endDate.split('-').join('/'))
                }}
              </strong>
            </div>
            <div>
              <label>VS入会後自動特典付与</label>
              <strong> {{ signUpBonusDetail.vsBonuses }} </strong>
            </div>
            <div>
              <label>特典コード</label>
              <strong> {{ signUpBonusDetail.vsPromoCode }} </strong>
            </div>
            <div>
              <label>付与ポイント/割引料金</label>
              <strong> {{ signUpBonusDetail.vsBonusValues }} </strong>
            </div>
            <div>
              <label>特典種別</label>
              <strong>
                {{
                  signUpBonusDetail.type === 'Discount'
                    ? '値引き'
                    : signUpBonusDetail.type === 'Point'
                    ? 'ポイント付与'
                    : signUpBonusDetail.type === 'Voucher'
                    ? '商品券'
                    : signUpBonusDetail.type === 'Mile'
                    ? 'マイル付与'
                    : ''
                }}
              </strong>
            </div>
            <div>
              <label>数値</label>
              <strong> {{ signUpBonusDetail.amount || '' }} </strong>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" lg="4" md="6">
        <div class="t-wrapper elevation-3 rounded">
          <div class="t-table">
            <div>
              <label style="color: #000000; font-weight: bold"
                >契約特典内容</label
              >
            </div>
            <div class="word-break">
              <span style="color: #000000; font-weight: bold">
                {{ signUpBonusDetail.description }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-center">
            <v-btn
              class="t-btn--prm"
              :disabled="!checkUser"
              @click="
                isShowEditDialog = true;
                indexShow = indexShow + 1;
              "
            >
              契約特典内容編集</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- End - List data -->

    <!-- Contract linked with contract benefits -->
    <v-card-title v-once class="px-5 pt-5 pb-0">
      <h3 class="page-title-list">契約特典が紐づいた契約</h3>
    </v-card-title>
    <v-card class="pb-5 btn-page-right" elevation="0">
      <Table
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
        }"
        ref="table"
        defaultSortBy="addDay"
        :itemsPerPage="10"
        :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
        :headers="headers"
        :filter="{}"
        :items="signUpBonusAppList.items"
        :total="signUpBonusAppList.total"
        :funReset="fetchData"
        :sortField="[]"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-menu
            offset-x
            :nudge-left="28"
            :nudge-bottom="12"
            content-class="bg--white pa-3"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :disabled="!checkUser"
                :color="
                  item.handledAt ? 'var(--button_gray)' : 'var(--button_green)'
                "
                label
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ item.handledAt ? '実施済' : '未実施' }}
              </v-chip>
            </template>
            <v-select
              :items="statusEditOptions"
              :value="item.handledAt ? 'Handled' : 'New'"
              item-text="text"
              item-value="value"
              hide-details
              @change="updateStatusItem(item.id, $event)"
            ></v-select>
          </v-menu>
        </template>
        <template v-slot:[`item.addDay`]="{ item }">
          {{
            item.createdAt
              .substr(0, 10)
              .split('-')
              .join('/')
          }}
        </template>
        <template v-slot:[`item.statusContract`]="{ item }">
          {{ item.contract.status }}
        </template>
        <template v-slot:[`item.productContract`]="{ item }">
          {{ item.contract.productType.code }}
        </template>
        <template v-slot:[`item.represent`]="{ item }">
          {{ item.contract.salesStaff && item.contract.salesStaff.name }}
        </template>
        <template v-slot:[`item.stage`]="{ item }">
          {{ item.contract.phase && item.contract.phase.name }}
        </template>

        <template v-slot:[`item.detailContract`]="{ item }">
          <v-btn icon class="t-btn--prm" @click="getUrl(item)">
            <v-icon class="icon-btn">mdi-card-account-details</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.privateCompany`]="{ item }">
          {{ item.contract.client.isCompany ? '法人' : '個人' }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            icon
            class="t-btn--prm"
            :to="
              '/customer-details/' +
                item.contract.client.id +
                '?typeClient=' +
                item.contract.client.type
            "
            target="_blank"
          >
            <v-icon class="icon-btn">mdi-human-handsup</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-card>
    <!-- End Contract linked with contract benefits -->

    <AdvertisingPoliciesDialog
      :visible="openDialogEdit"
      @close="openDialogEdit = false"
      :idPolicy="idPolicy"
      className="--child"
    />
    <contract-special-edit-dialog
      v-if="isShowEditDialog"
      @close="isShowEditDialog = false"
      :detail="{ ...signUpBonusDetail }"
      :key="indexShow"
      @fetchData="$emit('fetchData')"
    ></contract-special-edit-dialog>
  </v-dialog>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { convertCurrency } from '@/constants/functions';
import AdvertisingPoliciesDialog from '../policies/advertisingPoliciesDialog.vue';
import ContractSpecialEditDialog from './contractSpecialEditDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import Table from '@/components/Table/index.vue';

export default {
  name: 'ContractPrivilegeName',
  data() {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      indexShow: 0,
      isShowEditDialog: false,
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      openDialogEdit: false,
      statusOptions: [
        {
          text: '全て',
          value: null,
        },
        {
          text: '未実施',
          value: false,
        },
        {
          text: '実施済み',
          value: true,
        },
      ],
      statusEditOptions: [
        {
          text: '未実施',
          value: 'New',
        },
        {
          text: '実施済',
          value: 'Handled',
        },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      datesPicker: ['2019-09-10', '2019-09-20'],
      items: ['検討中', '未契約'],
      headers: [
        {
          text: '追加日',
          value: 'addDay',
          fieldName: 'application.id'
        },
        {
          text: '契約ステータス',
          value: 'statusContract',
          sortable: false,
        },
        {
          text: '契約商品',
          value: 'productContract',
          fieldName: 'productType.code'
        },
        {
          text: 'フェーズ',
          value: 'stage',
          fieldName: 'phase.name',
        },
        {
          text: '販売担当',
          value: 'represent',
          fieldName: 'salesStaff.name',
        },
        {
          text: '契約詳細',
          value: 'detailContract',
          sortable: false,
        },
        {
          text: '個人/法人',
          value: 'privateCompany',
          fieldName: 'client.isCompany',
        },
        {
          text: '顧客名',
          value: 'contract.client.name',
          sortable: false,
        },
        {
          text: '法人窓口担当者',
          value: 'contract.client.companyPicName',
          fieldName: 'client.companyPicName',
        },
        {
          text: '法人窓口担当者役職名',
          value: 'contract.client.companyPicTitle',
          fieldName: 'client.companyPicTitle',
        },
        { text: '顧客詳細', value: 'action', sortable: false },
      ],
    };
  },
  props: {
    visible: Boolean,
    idPolicy: [Number, String],
    namePolicy: String,
    id: Number,
  },
  computed: {
    ...mapGetters(['signUpBonusDetail', 'signUpBonusAppList']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    id() {
      this.$refs.table.reset();
    }
  },
  methods: {
    ...mapActions([
      'markAsHandledSignupBonusApplication',
      'fetchSignUpBonusApplicationList',
    ]),
    async updateStatusItem(id, status) {
      await this.markAsHandledSignupBonusApplication({ id, status });
      this.$refs.table.reset();
    },
    async fetchData({ pagination, orderBy }) {
      await this.fetchSignUpBonusApplicationList({
        id: this.id,
        ...pagination,
        orderBy,
      });
    },
    convertCurrency,
    getNumOfPage(pagination) {
      this.numLength = pagination.itemsLength;
      this.itemStart = pagination.pageStart + 1;
      this.itemStop = pagination.pageStop;
    },
    getUrl(item) {
      let url = '';
      // const url = '/contract/basic-infomation';
      if (item.contract.productType.code === 'VS') {
        url = '/contract/vs-vs';
      } else if (item.contract.productType.code === 'PW') {
        url = '/contract/usage-situation-sp-pw';
      } else if (item.contract.productType.code === 'MW') {
        url = '/contract/usage-situation-mw';
      } else if (item.contract.productType.code === 'VM') {
        url = '/contract/usage-situation-vm';
      } else {
        url = '/contract/usage-situation-sp-pw';
      }
      const routeData = `${url}/${item.contract.id}/${item.contract.productType.code}?clientId=${item.contract.client.id}`;
      window.open(routeData, '_blank');
    },
  },
  components: { ContractSpecialEditDialog, AdvertisingPoliciesDialog, Table },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-footer {
    &__pagination {
      display: none !important;
    }
  }
  .v-dialog {
    background-color: var(--bg_white);
    max-width: 1400px;
  }
  .sort-select {
    max-width: 180px;
  }
  .v-chip {
    width: 100%;
    color: var(--text_white);
    justify-content: center;
  }
}
.t-wrapper {
  height: 100%;
  background-color: var(--bg_white);
}
.t-table {
  border-spacing: 0 10px;
  padding: 16px;
  &:not(.text) {
    display: table;
    width: 100%;
    > div {
      display: table-row;
      label,
      strong,
      .t-table__cell {
        display: table-cell;
      }
      strong,
      .t-table__cell {
        text-align: right;
      }
    }
  }
}
a.d-block {
  color: var(--text_primary);
}
</style>
