<template>
  <main-layout>
    <contract-detail>
      <!-- <template v-slot:alo>Xin chào</template> -->
      <!-- Title -->
      <template v-slot:title>{{ $route.params.type }}契約</template>
      <!-- End Title -->

      <!-- Content  -->
      <template v-slot:default>
        <template>
          <div class="mx-10" style="width: 80%">
            <div class="d-flex justify-end">
              <v-btn class="t-btn--red-dark" @click="$windowClose">
                <v-icon size="22">mdi-close</v-icon>
                <span style="font-size:14px">&emsp;閉じる</span>
              </v-btn>
              <v-btn
                class="t-btn--prm ml-3"
                style="font-size:14px"
                @click="save"
                :disabled="!checkPerUser"
              >
                保存
              </v-btn>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card class="my-2 pa-5 ml-1">
                <div class="card1">
                  <h3 class=" mb-10">契約時情報</h3>
                  <!-- Example contractId = 1 -->
                  <v-row
                    v-if="
                      finishData.productTypeId === 2 ||
                        finishData.productTypeId === 3
                    "
                  >
                    <v-col :cols="3">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="itemsData.menuSpFirstDate"
                        offset-y
                        transition="scale-transition"
                        min-width="auto"
                        :disabled="!checkPerUser"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <p class="label-title">ポイント初年度利用開始日</p>
                            <!-- placeholder="XXXX/XX/XX" -->
                            <v-text-field
                              persistent-hint
                              v-model="computedDateSpFirstDateFormatted"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="field"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="finishData.spFirstDate"
                          :locale="$i18n.locale"
                          @input="itemsData.menuSpFirstDate = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col :cols="3">
                      <v-text-field
                        dense
                        label="シェアリングポイント合計数"
                        v-model="finishData.totalPoint"
                        class="mt-5 field"
                        :rules="rules.number"
                        :disabled="!checkPerUser"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">契約時単価</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.pointPrice"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <!-- Example contractId = 19 -->
                  <v-row v-if="finishData.productTypeId === 6">
                    <v-col :cols="3">
                      <p class="label-title">PWで利用しているSP単価</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.pointPrice"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <v-text-field
                        dense
                        label="利用開始年"
                        v-model="finishData.year"
                        :rules="rules.year"
                        :disabled="!checkPerUser"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="3">
                      <v-select
                        v-model="finishData.month"
                        item-text="name"
                        item-value="id"
                        label="利用開始月"
                        dense
                        :items="itemsData.months"
                        hide-details
                        class="mt-1 select"
                        :disabled="!checkPerUser"
                      ></v-select>
                    </v-col>
                    <v-col :cols="3">
                      <v-text-field
                        dense
                        label="契約年数"
                        v-model="finishData.years"
                        :rules="rules.years"
                        :disabled="!checkPerUser"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <v-combobox
                        v-model="finishData.responseSource"
                        label="反響ソース"
                        dense
                        :items="itemsData.responseSource"
                        hide-details
                        class="mt-1 select"
                        :disabled="!checkPerUser"
                      ></v-combobox>
                    </v-col>
                    <v-col :cols="3">
                      <v-select
                        :items="itemsData.contractPurchaseTypeList"
                        item-text="name"
                        item-value="id"
                        v-model="finishData.contractPurchaseTypeId"
                        label="購入種別"
                        dense
                        hide-details
                        class="mt-1 select"
                        :disabled="!checkPerUser"
                      ></v-select>
                    </v-col>
                    <v-col :cols="3">
                      <v-select
                        :items="itemsData.contractPurchaseTypeDetailList"
                        item-text="name"
                        item-value="id"
                        v-model="finishData.contractPurchaseTypeDetailId"
                        label="購入種別詳細"
                        dense
                        hide-details
                        class="mt-1 select"
                        :disabled="!checkPerUser"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-7 mb-3">
                    <v-col :cols="3">
                      <v-select
                        :items="itemsData.staffList"
                        item-text="name"
                        item-value="id"
                        v-model="finishData.salesStaffId"
                        label="販売担当者"
                        dense
                        hide-details
                        class="mt-1 select"
                        :disabled="!checkPerUser"
                      ></v-select>
                    </v-col>
                    <v-col :cols="3">
                      <v-combobox
                        v-model="finishData.purchaseReason"
                        label="購入動機"
                        dense
                        :items="itemsData.contractPurchaseReasonList"
                        class="mt-1 select"
                        :disabled="!checkPerUser"
                      ></v-combobox>
                    </v-col>
                    <v-col :cols="3">
                      <v-select
                        v-model="finishData.mainPurchasePurposeId"
                        item-text="name"
                        item-value="id"
                        label="主な利用目的"
                        dense
                        :items="itemsData.contractPurchasePurposeList"
                        hide-details
                        class="mt-1 select"
                        :disabled="!checkPerUser"
                      ></v-select>
                    </v-col>
                    <v-col :cols="3">
                      <v-select
                        v-model="finishData.subPurchasePurposeId"
                        item-text="name"
                        item-value="id"
                        label="サブの利用目的"
                        dense
                        :items="itemsData.contractPurchasePurposeList"
                        hide-details
                        class="mt-1 select"
                        :disabled="!checkPerUser"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="itemsData.menuApplicationDate"
                        offset-y
                        transition="scale-transition"
                        min-width="auto"
                        :disabled="!checkPerUser"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <p class="label-title">申込日</p>
                            <!-- placeholder="XXXX/XX/XX" -->
                            <v-text-field
                              persistent-hint
                              v-model="computedDateApplicationDateFormatted"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="field"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="finishData.applicationDate"
                          :locale="$i18n.locale"
                          @input="itemsData.menuApplicationDate = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col :cols="3">
                      <v-select
                        :items="itemsData.contractApplicationTypeList"
                        item-text="name"
                        item-value="id"
                        v-model="finishData.applicationTypeId"
                        label="申込方法"
                        dense
                        hide-details
                        class="mt-5 select"
                        :disabled="!checkPerUser"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="itemsData.menuPaymentExpectedDate"
                        offset-y
                        transition="scale-transition"
                        min-width="auto"
                        :disabled="!checkPerUser"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <p class="label-title">入金予定日</p>
                            <!-- placeholder="XXXX/XX/XX" -->
                            <v-text-field
                              persistent-hint
                              v-model="computedDatePaymentExpectedDateFormatted"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="field"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="finishData.paymentExpectedDate"
                          :locale="$i18n.locale"
                          @input="itemsData.menuPaymentExpectedDate = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col :cols="3">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="itemsData.menuPaymentDate"
                        offset-y
                        transition="scale-transition"
                        min-width="auto"
                        :disabled="!checkPerUser"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <p class="label-title">入金日</p>
                            <!-- placeholder="XXXX/XX/XX" -->
                            <v-text-field
                              persistent-hint
                              v-model="computedDatePaymentDateFormatted"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              class="field"
                              :disabled="!checkPerUser"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                          v-model="finishData.paymentDate"
                          :locale="$i18n.locale"
                          @input="itemsData.menuPaymentDate = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <p class="label-title">入会金(税抜)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.entranceFee"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">入会金(消費税)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.entranceFeeTax"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <p class="label-title">会員登録料(税抜金額)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.memberRegistrationFee"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">会員登録料(消費税)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.memberRegistrationFeeTax"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <p class="label-title">
                        年会費・メンバーシップフィー(税抜金額)
                      </p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.annualFee"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">
                        年会費・メンバーシップフィー(消費税)
                      </p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.annualFeeTax"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <p class="label-title">管理費(税抜金額)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.managementFee"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">管理費(消費税)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.managementFeeTax"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">温泉維持費(税抜金額)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.hotspringFee"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">温泉維持費(消費税)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.hotspringFeeTax"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <p class="label-title">支払総額(税抜金額)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.paymentTotal"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">支払総額(消費税)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.paymentTotalTax"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <p class="label-title">入会金割引額(税抜金額)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.entranceFeeDiscount"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">入会金割引額(消費税)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.entranceFeeDiscountTax"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <p class="label-title">支払総額(税込)</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.paymentTotalWithTax"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <v-select
                        dense
                        label="入金種別"
                        :items="itemsData.contractPurchasePaymentMethodList"
                        item-text="name"
                        item-value="id"
                        v-model="finishData.purchasePaymentMethodId"
                        class="mt-5 select"
                        :disabled="!checkPerUser"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <p class="label-title">入金金額</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        v-model="finishData.receivedPayment"
                        className="w-100 curency-input"
                        :rules="rules.numberCurrency"
                      ></CurrencyInput>
                    </v-col>
                    <v-col :cols="3">
                      <p class="label-title">入金額差異</p>
                      <!-- placeholder="¥XX,XXX" -->
                      <CurrencyInput
                        :value="getReceivedPaymentDifference(finishData)"
                        readonly
                        className="w-100 curency-input"
                      ></CurrencyInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <v-text-field
                        dense
                        label="経理チェック"
                        v-model="finishData.accountingCheck"
                        :rules="[$rules.checkLenghInput(255)]"
                        :disabled="!checkPerUser"
                      ></v-text-field>
                    </v-col>
                    <v-col :cols="6">
                      <v-text-field
                        dense
                        label="経理伝達事項"
                        v-model="finishData.accountingNotes"
                        :rules="[$rules.checkLenghInput(255)]"
                        :disabled="!checkPerUser"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col :cols="3">
                      <v-btn
                        class="t-btn--prm"
                        @click="
                          dialog = 1;
                          idxCreate = idxCreate + 1;
                        "
                      >
                        契約時書類を追加
                      </v-btn>
                    </v-col>
                  </v-row>

                  <div class="gray">
                    <v-btn
                      class="btn-custom  mr-2 my-2"
                      v-for="(item, idx) in finishData.attachments"
                      :key="idx"
                      style="display: inline"
                    >
                      <span class="mr-1 mb-1 px-6 mt-2"
                        ><a
                          :href="item.fileUrl"
                          target="_blank"
                          style="text-decoration: none"
                          >{{ item.name }}</a
                        ></span
                      >
                      <v-icon @click="actionDelete(item.id)">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-form>
            <v-card class="pa-5 ml-1 my-5">
              <div class="d-flex justify-space-between mb-1">
                <h3
                  class="color-blu--ob mb-3"
                  style="font-size: 18px !important"
                >
                  広告施策(顧客についてるものから1つまで)
                </h3>
                <div class="d-flex justify-space-between">
                  <v-select
                    :items="itemsData.contractCampaignList"
                    item-text="name"
                    item-value="id"
                    v-model="campaignIdSelected"
                    dense
                    outlined
                    label="広告施策追加"
                    class="select"
                  ></v-select>
                  <v-btn
                    small
                    class="btn-crm-primary ml-2"
                    @click="handleAddContract"
                    :disabled="!checkPerUser"
                  >
                    追加
                  </v-btn>
                </div>
              </div>
              <!-- v-if="itemsData.dataCampaignList" -->
              <Table
                :attr="{
                  dense: true,
                  'no-data-text': $t('rules.noData'),
                  'item-key': 'id',
                }"
                ref="table"
                :itemsPerPage="30"
                :itemsPerPageOptions="[30, 60, 120, 480, 1000]"
                :headers="headerAdvertisingMeasures"
                :items="itemsData.dataCampaignList"
                :hideFooter="true"
                :disableSort="true"
              >
                <template v-slot:[`item.Groups`]="{ item }">
                  <span>{{ item && item.groups.join(', ') }}</span>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <span>{{ item && formatDate2(item.createdAt) }}</span>
                </template>
                <template v-slot:[`item.Attachment1`]="{ item }">
                  <v-btn
                    icon
                    @click="openPdfFileInNewTab(item.attachment1Url)"
                    v-if="item && item.attachment1Url"
                  >
                    <i
                      style="bacground-color: #13ace0"
                      class="fas fa-file-download btnCustome"
                    ></i>
                  </v-btn>
                </template>
                <template v-slot:[`item.Attachment2`]="{ item }">
                  <v-btn
                    icon
                    @click="openPdfFileInNewTab(item.attachment2Url)"
                    v-if="item && item.attachment2Url"
                  >
                    <i
                      style="bacground-color: #13ace0"
                      class="fas fa-file-download btnCustome"
                    ></i>
                  </v-btn>
                </template>
                <template v-slot:[`item.Attachment3`]="{ item }">
                  <v-btn
                    icon
                    @click="openPdfFileInNewTab(item.attachment3Url)"
                    v-if="item && item.attachment3Url"
                  >
                    <i
                      style="bacground-color: #13ace0"
                      class="fas fa-file-download btnCustome"
                    ></i>
                  </v-btn>
                </template>
                <template v-slot:[`item.delete`]="{}">
                  <v-btn
                    class="t-btn--red-dark"
                    icon
                    @click="handleDeleteContract()"
                    :disabled="!checkPerUser"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.detail`]="{ item }">
                  <v-btn icon @click="(dialog = 3), (idPolicy = item.id)">
                    <i
                      style="bacground-color: #13ace0;"
                      class="fas fa-ad btnCustomAd"
                    ></i>
                  </v-btn>
                </template>
              </Table>
            </v-card>
            <v-card class="pa-5 ml-1 mb-16">
              <div class="d-flex justify-space-between mb-1">
                <h3
                  class="color-blu--ob mb-3"
                  style="font-size: 18px !important"
                >
                  契約特典(3つまで)
                </h3>
                <div class="d-flex justify-space-between">
                  <v-select
                    :items="itemsData.itemsSignupBonusApplicationList"
                    item-text="name"
                    item-value="id"
                    v-model="signupBonusIdSelected"
                    dense
                    outlined
                    label="契約特典追加"
                    class="select"
                  ></v-select>
                  <v-btn
                    small
                    class="btn-crm-primary ml-2"
                    @click="handleAddSignupBonusApplication"
                    :disabled="!checkPerUser"
                  >
                    追加
                  </v-btn>
                </div>
              </div>
              <Table
                :attr="{
                  dense: true,
                  'no-data-text': $t('rules.noData'),
                  'item-key': 'id',
                }"
                ref="table"
                :itemsPerPage="30"
                :itemsPerPageOptions="[30, 60, 120, 480, 1000]"
                :headers="headerContractSenefits"
                :items="itemsData.signupBonusApplicationList"
                :hideFooter="true"
                :disableSort="true"
              >
                <template v-slot:[`item.SignupBonusType`]="{ item }">
                  <span v-if="item.signupBonus.type === 'Discount'"
                    >値引き</span
                  >
                  <span v-if="item.signupBonus.type === 'Point'"
                    >ポイント付与</span
                  >
                  <span v-if="item.signupBonus.type === 'Voucher'">商品券</span>
                  <span v-if="item.signupBonus.type === 'Mile'"
                    >マイル付与</span
                  >
                </template>
                <template v-slot:[`item.signupBonus.name`]="{ item }">
                  {{ item.signupBonus ? item.signupBonus.name : '' }}
                </template>
                <template v-slot:[`item.signupBonus.description`]="{ item }">
                  {{ item.signupBonus ? item.signupBonus.description : '' }}
                </template>
                <template v-slot:[`item.signupBonus.amount`]="{ item }">
                  {{ item.signupBonus ? item.signupBonus.amount : '' }}
                </template>
                <template v-slot:[`item.signupBonus.vsBonuses`]="{ item }">
                  {{ item.signupBonus ? item.signupBonus.vsBonuses : '' }}
                </template>
                <template v-slot:[`item.signupBonus.vsBonusValues`]="{ item }">
                  {{ item.signupBonus ? item.signupBonus.vsBonusValues : '' }}
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                  <v-btn
                    class="t-btn--red-dark"
                    icon
                    @click="handleDeleteSignUpBonusApplication(item.id)"
                    :disabled="!checkPerUser"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.detail`]="{ item }">
                  <v-btn icon @click="showPrivilegeDialog(item.signupBonus.id)">
                    <i
                      style="bacground-color: #13ace0"
                      class="fas fa-ad btnCustomAd"
                    ></i>
                  </v-btn>
                </template>
              </Table>
              <!-- <v-data-table
                :headers="headerContractSenefits"
                :items="itemsData.signupBonusApplicationList"
                hide-default-footer
                disable-sort
                no-data-text="データなし"
                class="custom-table-2"
                dense
              >
                <template v-slot:[`item.SignupBonusType`]="{ item }">
                  <span v-if="item.signupBonus.type === 'Discount'"
                    >値引き</span
                  >
                  <span v-if="item.signupBonus.type === 'Point'"
                    >ポイント付与</span
                  >
                  <span v-if="item.signupBonus.type === 'Voucher'">商品券</span>
                  <span v-if="item.signupBonus.type === 'Mile'"
                    >マイル付与</span
                  >
                </template>
                <template v-slot:[`item.delete`]="{ item }">
                  <v-btn
                    class="t-btn--red-dark"
                    icon
                    @click="handleDeleteSignUpBonusApplication(item.id)"
                    :disabled="!checkPerUser"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.detail`]="{ item }">
                  <v-btn icon @click="showPrivilegeDialog(item.signupBonus.id)">
                    <i
                      style="bacground-color: #13ace0"
                      class="fas fa-ad btnCustomAd"
                    ></i>
                  </v-btn>
                </template>
              </v-data-table> -->
            </v-card>

            <!-- Dialog -->
            <upload-documents
              :key="idxCreate"
              :visible="dialog === 1"
              @close="dialog = -1"
              @uploadAdd="getContractTimeInformationInital"
            ></upload-documents>

            <AdvertisingPoliciesDetailDialog
              v-if="dialog === 3"
              @close="dialog = -1"
              :idPolicy="idPolicy"
              @fetchList="reloadData"
            />

            <contract-privilege-name
              :visible="dialog === 4"
              @close="dialog = -1"
              :id="currentId"
              @fetchData="fetchData"
            ></contract-privilege-name>
            <!-- End Dialog -->
          </div>
        </template>
      </template>
      <!-- End Content  -->
    </contract-detail>
  </main-layout>
</template>

<script>
import UploadDocuments from './UploadDocuments.vue';
import contractDetail from '../../ContractDetail.vue';
import { mapActions, mapMutations } from 'vuex';
import { checkNumber } from '@/utils/validate';
import CurrencyInput from '@/components/Input/CurrencyInput.vue';
import { MONTHS } from '@/constants/constantTimes.js';
import MainLayout from '@/layouts/MainLayout';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import moment from 'moment';
import ContractPrivilegeName from '@/views/advertising/contractSpecial/ContractPrivilegeName.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import gql from 'graphql-tag';
import { CAMPAIGN_LIST } from '@/api/graphql/advertising/advertising';
import AdvertisingPoliciesDetailDialog from '@/views/advertising/policies/advertisingPoliciesDetailDialog.vue';
import { getFirstDateOfMonth } from '@/utils/dateUtil';
import { getReceivedPaymentDifference } from '@/utils/contract'
import Table from '@/components/Table/index.vue';

export default {
  components: {
    UploadDocuments,
    contractDetail,
    CurrencyInput,
    MainLayout,
    ContractPrivilegeName,
    AdvertisingPoliciesDetailDialog,
    Table,
  },
  name: 'ContractTimeInformation',
  data() {
    return {
      idPolicy: null,
      idxCreate: 0,
      currentId: null,
      status: false,
      keyword: null,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      valid: true,
      dialog: -1,
      currentAttachment: null,
      // data table
      AdvertisingMeasures: [],
      headerAdvertisingMeasures: [
        {
          text: this.$t(
            'task_29786.ProspectInformationTab.AdvertisingMeasureName',
          ),
          value: 'name',
          // width: '150px',
        },
        {
          text: this.$t(
            'task_29786.ProspectInformationTab.AdvertisingPolicyGroupName',
          ),
          value: 'Groups',
          // width: '170px',
        },
        {
          text: this.$t('task_29786.ProspectInformationTab.DateOfAddition'),
          value: 'createdAt',
          // width: '100px',
        },
        {
          text: '説明',
          value: 'note',
          // width: '330px',
        },
        {
          text: this.$t('task_29786.ProspectInformationTab.ShippingMaterials'),
          value: 'Attachment1',
          // width: '100px',
        },
        {
          text: this.$t('task_29786.ProspectInformationTab.ShippingMaterials'),
          value: 'Attachment2',
          // width: '100px',
        },
        {
          text: this.$t('task_29786.ProspectInformationTab.ShippingMaterials'),
          value: 'Attachment3',
        },
        {
          text: this.$t('task_29786.ProspectInformationTab.delete'),
          value: 'delete',
          // width: '100px',
        },
        {
          text: '施策詳細',
          value: 'detail',
          // width: '100px',
        },
      ],
      ContractSenefits: [],
      headerContractSenefits: [
        {
          text: '特典名',
          value: 'signupBonus.name',
          width: '130px',
        },
        {
          text: '特典内容',
          value: 'signupBonus.description',
          width: '200px',
        },
        {
          text: '数値種別',
          value: 'SignupBonusType',
          width: '150px',
        },
        {
          text: '数値',
          value: 'signupBonus.amount',
          width: '80px',
        },
        {
          text: 'VS入会後自動特典付与',
          value: 'signupBonus.vsBonuses',
          width: '200px',
        },
        {
          text: 'ポイント/料金',
          value: 'signupBonus.vsBonusValues',
          width: '120px',
        },
        {
          text: this.$t('task_29786.ProspectInformationTab.delete'),
          value: 'delete',
          width: '70px',
        },
        {
          text: '特典詳細',
          value: 'detail',
          width: '90px',
        },
      ],
      // data
      PointFirstYearStartDate: 'XXXX/XX/XX',
      AdditionalPurchasePrice: '',
      BankPrice: '¥XX,XXX',
      SPUnitPriceUsedByPW: '¥XX,XXX',
      YearOfUse: '',
      MonthOfUse: '',
      ContractYears: '',
      PurchaseTypeDetails: '',
      PurchaseType: '',
      EchoSource: '',
      SalesPerson: '',
      MotivationTopurchase: '',
      MainPurposeOfuse: '',
      PurposeOfUseOfSub: '',
      ApplicationDate: '',
      HowToApply: '',
      plannedDepositDate: 'XXXX/XX/XX',
      PaymentDay: 'XXXX/XX/XX',
      AdmissionFee: '¥XX,XXX',
      AdmissionFeeCT: '¥XX,XXX',
      MembershipRegistrationFee: '¥XX,XXX',
      MembershipRegistrationFeeCT: '¥XX,XXX',
      AnnualMembershipFee: '¥XX,XXX',
      AnnualMembershipFeeCT: '¥XX,XXX',
      ManagementFee: '¥XX,XXX',
      ManagementFeeCT: '¥XX,XXX',
      HotSpringMaintenanceFee: '¥XX,XXX',
      HotSpringMaintenanceFeeCT: '¥XX,XXX',
      TotalPayment: '¥XX,XXX',
      TotalPaymentCT: '¥XX,XXX',
      AdmissionFeeDiscountAmount: '¥XX,XXX',
      AdmissionFeeDiscountAmountCT: '¥XX,XXX',
      TotalPaymentTI: '¥XX,XXX',
      DepositType: '¥XX,XXX',
      DepositAmount: '¥XX,XXX',
      DepositAmountDifference: '¥XX,XXX',
      AccountingCheck: '',
      selectAdvertisingMeasures: '追加する契約特典を選択',
      selectContractSenefits: '追加する契約特典を選択',

      rules: {
        number: [
          v => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32'),
        ],
        numberCurrency: [
          v =>
            (v && checkNumber(v.replace(/¥|,/g, ''))) ||
            !v ||
            this.$t('rules.numberInvalid'),
          v =>
            (v && parseInt(v.replace(/¥|,/g, '')) <= 2147483647) ||
            !v ||
            this.$t('rules.maximumNumberInt32'),
        ],
        // text: [
        //   v =>
        //     (v && CheckLenghInput(v, 255)) ||
        //     !v ||
        //     this.$t('rules.maximumNCharacter', { value: 255 }),
        // ],
        year: [
          v =>
            (v && v.length === 4) ||
            (v && v.length === undefined) ||
            !v ||
            this.$t('rules.exactNCharacters', { value: 4 }),
          v => checkNumber(v) || !v || this.$t('rules.numberInvalid'),
          v =>
            (v && v >= 1000) ||
            !v ||
            this.$t('rules.minimumN', { value: 1000 }),
        ],
        years: [
          v => !!v || this.$t('rules.requiredField'),
          v => (v && checkNumber(v)) || this.$t('rules.numberInvalid'),
          v => (v && v <= 2147483647) || this.$t('rules.maximumNumberInt32'),
        ],
      },
      contractData: null,
      campaignIdSelected: null,

      signupBonusIdSelected: null,
      itemsData: {
        // 契約時情報
        // ポイント初年度利用開始日
        menuSpFirstDate: false,

        months: MONTHS,
        responseSource: [],
        contractPurchaseTypeList: [],
        contractPurchaseTypeDetailList: [],
        staffList: [],
        contractPurchaseReasonList: [],
        contractPurchasePurposeList: [],

        menuApplicationDate: false,

        contractApplicationTypeList: [],

        menuPaymentExpectedDate: false,
        menuPaymentDate: false,
        contractPurchasePaymentMethodList: [],
        dataCampaignList: [],
        itemsCampaignApplyResponseList: [],
        signupBonusApplicationList: [],
        itemsSignupBonusApplicationList: [],
        contractCampaignList: [],
      },
      // Btn Edit:
      finishData: {
        productTypeId: null, // Int // enumProductTypeList {id name}
        contractId: parseInt(this.$router.currentRoute.params.id), // Int!
        // 契約時情報
        // If SP/EP productTypeId = 2/3
        // ポイント初年度利用開始日
        spFirstDate: null, // DateString
        // シェアリングポイント合計数
        totalPoint: null, // Int
        // 契約時単価
        // If SP/FP productTypeId = 2/3
        // PWで利用しているSP単価
        // PW productTypeId = 6
        pointPrice: null, // Int

        // 利用開始年
        year: null, // Int 0000-9999
        // 利用開始月
        month: null, // Int 1-12
        startDate: null, // DateString 理科用開始日（画面上年月に分かれているため、new Date(startDate).getFullYear()が利用開始年 bên trên、 .getMonth() + 1 が利用開始月 bên trên に入るように入れて下さい
        // 契約年数
        years: null, // Int Ko có trong API get và edit
        // 反響ソース
        responseSource: null, // String enumResponseSourceList {name} かComboboxなので直接入力
        // 購入種別
        contractPurchaseTypeId: null, // Int 購入種別ID。選択肢は  enumContractPurchaseTypeList {id name} で取得
        // 購入種別詳細
        contractPurchaseTypeDetailId: null, // Int 購入種別詳細 選択肢は enumContractPurchaseTypeDetailList {id name}
        // 販売担当者
        salesStaffId: null, // Int 販売担当者、選択肢は staffList(crmRep:true) {id name} で出す
        // 購入動機
        purchaseReason: null, // String 購入動機  Combobox 選択肢は enumContractPurchaseReasonList{name}
        // 主な利用目的
        mainPurchasePurposeId: null, // Int enumContractPurchasePurposeList{id name}
        // サブの利用目的
        subPurchasePurposeId: null, // Int enumContractPurchasePurposeList{id name} giống bên trên
        // 申込日
        applicationDate: null, // DateString
        // 申込方法
        applicationTypeId: null, // Int enumContractApplicationTypeList{ id name}
        // 入金予定日
        paymentExpectedDate: null, // DateString
        // 入金日
        paymentDate: null, // DateString
        // 入会金 (税抜)
        entranceFee: null, // Int
        // 入会金(消費税)
        entranceFeeTax: null, // Int
        // 会員登録料（税抜金額）
        memberRegistrationFee: null, // Int
        // 会員登録料（消費税）
        memberRegistrationFeeTax: null, // Int
        // 年会費・メンバーシップフィー（税抜金額）
        annualFee: null, // Int
        // 年会費・メンバーシップフィー（消費税）
        annualFeeTax: null, // Int
        // 管理費（税抜金額）
        managementFee: null, // Int
        // 管理費（消費税）
        managementFeeTax: null, // Int
        // 温泉維持費（税抜金額）
        hotspringFee: null, // Int
        // 温泉維持費（消費税）
        hotspringFeeTax: null, // Int
        // 支払総額(税抜金額)
        paymentTotal: null, // Int
        // 支払総額(消費税)
        paymentTotalTax: null, // Int
        // 入会金割引額（税抜金額）
        entranceFeeDiscount: null, // Int
        // 入会金割引額(消費税)
        entranceFeeDiscountTax: null, // Int
        // 支払総額(税込)
        paymentTotalWithTax: null, // Int
        // 入金種別
        purchasePaymentMethodId: null, // Int
        // 入金金額
        receivedPayment: null, // Int
        // 経理チェック
        accountingCheck: null, // String
        // 経理伝達事項
        accountingNotes: null, // string
        attachments: [],
      },
      campaignList: [],
    };
  },
  computed: {
    // 契約時情報
    // ポイント初年度利用開始日
    computedDateSpFirstDateFormatted() {
      return this.formatDate(this.finishData.spFirstDate);
    },
    // 申込日
    computedDateApplicationDateFormatted() {
      return this.formatDate(this.finishData.applicationDate);
    },
    // 入金予定日
    computedDatePaymentExpectedDateFormatted() {
      return this.formatDate(this.finishData.paymentExpectedDate);
    },
    // 入金日
    computedDatePaymentDateFormatted() {
      return this.formatDate(this.finishData.paymentDate);
    },
  },
  created() {
    this.reloadData();
    this.getCampaignListDialog3();
    this.getContractTimeInformationInital();
    this.actionEnumResponseSourceList().then(actionEnumResponseSourceList => {
      const arr = [];
      actionEnumResponseSourceList.forEach(element => {
        arr.push(element.name);
      });
      this.itemsData.responseSource = arr;
    });
    this.actionEnumContractPurchaseTypeList().then(
      enumContractPurchaseTypeList => {
        this.itemsData.contractPurchaseTypeList = enumContractPurchaseTypeList;
      },
    );
    this.actionEnumContractPurchaseTypeDetailList().then(
      enumContractPurchaseTypeDetailList => {
        this.itemsData.contractPurchaseTypeDetailList = enumContractPurchaseTypeDetailList;
      },
    );
    this.actionStaffList().then(staffList => {
      this.itemsData.staffList = staffList;
    });
    this.actionEnumContractPurchaseReasonList().then(
      enumContractPurchaseReasonList => {
        const arr = [];
        enumContractPurchaseReasonList.forEach(element => {
          arr.push(element.name);
        });
        this.itemsData.contractPurchaseReasonList = arr;
      },
    );
    this.actionEnumContractPurchasePurposeList().then(
      enumContractPurchasePurposeList => {
        this.itemsData.contractPurchasePurposeList = enumContractPurchasePurposeList;
      },
    );
    this.actionEnumContractApplicationTypeList().then(
      enumContractApplicationTypeList => {
        this.itemsData.contractApplicationTypeList = enumContractApplicationTypeList;
      },
    );
    this.actionEnumContractPurchasePaymentMethodList().then(
      enumContractPurchasePaymentMethodList => {
        this.itemsData.contractPurchasePaymentMethodList = enumContractPurchasePaymentMethodList;
      },
    );
    this.actionSignupBonusApplicationList(
      parseInt(this.$router.currentRoute.params.id),
    ).then(signupBonusApplicationList => {
      this.itemsData.signupBonusApplicationList = signupBonusApplicationList;
    });
    this.actionEnumSignUpBonusList().then(items => {
      this.itemsData.itemsSignupBonusApplicationList = items;
    });
  },
  // mounted() {
  //   // -----------
  //   this.getAdvertisingMeasures();
  //   this.getContractSenefits();
  // },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'actionContractTimeInformation',
      'actionUpdateContractTimeInformation',
      'actionEnumResponseSourceList',
      'actionEnumContractPurchaseTypeList',
      'actionEnumContractPurchaseTypeDetailList',
      'actionStaffList',
      'actionEnumContractPurchaseReasonList',
      'actionEnumContractPurchasePurposeList',
      'actionEnumContractApplicationTypeList',
      'actionEnumContractPurchasePaymentMethodList',
      'actionGetContractCampaign',
      'actionUpdateContractCampaign',
      'actionEnumCampaignApplyResponseList',
      'actionSignupBonusApplicationList',
      'actionDeleteSignUpBonusApplication',
      'actionAddSignupBonusApplication',
      'actionAddContract',
      'actionEnumSignUpBonusList',
      'actionDeleteContract',
      'fetchSignUpBonusList',
      'fetchSignUpBonusDetail',
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),

    openPdfFileInNewTab(url) {
      window.open(url);
    },

    actionDelete(id) {
      const query = `
            mutation($id: Int!) {
            deleteContractAttachment (id:$id) {
              id
            }
          }
          `;
      const variables = {
        id: id,
      };
      this.$apollo
        .mutate({
          mutation: gql`
            ${query}
          `,
          variables: variables,
        })
        .then(data => {
          this.setAlertSuccess(this.$t('messages.deletedSuccessfully'));
          this.finishData.attachments = this.finishData.attachments.filter(
            item => item.id !== id,
          );
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    save() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        const newFinishData = {
          ...this.finishData,
          years: parseInt(this.finishData.years),
          totalPoint: parseInt(this.finishData.totalPoint),
        };
        if (newFinishData.year && newFinishData.month) {
          const date = new Date(newFinishData.year, newFinishData.month - 1, 1);

          newFinishData.startDate = getFirstDateOfMonth(date);
        }
        delete newFinishData.clientId;
        delete newFinishData.productTypeId;
        delete newFinishData.contractId;
        delete newFinishData.year;
        delete newFinishData.month;
        delete newFinishData.contractPurchaseType;
        delete newFinishData.contractPurchaseTypeDetail;
        delete newFinishData.salesStaff;
        delete newFinishData.mainPurchasePurpose;
        delete newFinishData.subPurchasePurpose;
        delete newFinishData.applicationType;
        delete newFinishData.attachments;
        delete newFinishData.campaign;
        delete newFinishData.__typename;

        this.actionUpdateContractTimeInformation(newFinishData).then(result => {
          this.setAlertSuccess(this.$t('messages.successfulUpdate'));
        });
      } else {
        this.setAlertError(this.$t('messages.formValidationError'));
      }
    },
    // getAdvertisingMeasures() {
    //   for (let i = 0; i < 3; i++) {
    //     this.AdvertisingMeasures.push({
    //       AdvertisingMeasureName: 'XXXXXXXXXXXXXXX',
    //       AdvertisingPolicyGroupName: 'XXXXXXXXXXXXXXX',
    //       DateOfAddition: 'XXXX/XX/XX',
    //       explanation: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    //     });
    //   }
    // },
    // getContractSenefits() {
    //   for (let i = 0; i < 3; i++) {
    //     this.ContractSenefits.push({
    //       BenefitName: 'インターネット検索',
    //       Benefits: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
    //       NumericType: 'ポイント',
    //       NumericalValue: '2000',
    //       AutomaticPrivilege: 'ポイント付与',
    //       PointsCharges: '2000',
    //     });
    //   }
    // },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${year}/${month}/${day}`;
    },
    formatDate2(date) {
      if (!date) {
        return null;
      }
      return moment(date).format('YYYY/MM/DD');
    },

    handleAddContract() {
      if (this.campaignIdSelected) {
        const finishData = {
          contractId: parseInt(this.$router.currentRoute.params.id),
          campaignId: parseInt(this.campaignIdSelected),
        };
        this.actionUpdateContractCampaign(finishData).then(result => {
          this.reloadData();
        });
      } else {
        this.setAlertError('選んでください「広告施策追加」');
      }
    },

    reloadData() {
      this.actionGetContractCampaign().then(campaign => {
        this.itemsData.dataCampaignList = [];
        if (campaign) {
          this.itemsData.dataCampaignList.push(campaign);
        }
      });
    },

    handleDeleteContract() {
      const finishData = {
        contractId: parseInt(this.$router.currentRoute.params.id),
        campaignId: null,
      };
      this.actionUpdateContractCampaign(finishData).then(result => {
        this.itemsData.dataCampaignList = [];
      });
    },
    handleAddSignupBonusApplication() {
      const data = {
        contractId: parseInt(this.$router.currentRoute.params.id),
        signupBonusId: this.signupBonusIdSelected,
      };
      this.actionAddSignupBonusApplication(data).then(() => {
        this.actionSignupBonusApplicationList(
          parseInt(this.$router.currentRoute.params.id),
        ).then(signupBonusApplicationList => {
          this.itemsData.signupBonusApplicationList = signupBonusApplicationList;
        });
      });
    },
    getContractTimeInformationInital() {
      this.actionContractTimeInformation().then(contract => {
        this.finishData = {
          ...this.finishData,
          ...contract,
          year: contract.startDate
            ? new Date(contract.startDate).getFullYear()
            : null,
          month: contract.startDate
            ? new Date(contract.startDate).getMonth() + 1
            : null,
        };
        const query = `
              query ($pagination: PaginationInput, $filter: CampaignApplyResponseListFilterInput)         {
                campaignApplyResponseList (
                  filter: $filter,
                  pagination: $pagination
                ) {
                  items {
                    campaign {
                      name 
                      id
                    }
                  }
                }
              }
            `;
        const variables = {
          filter: {
            clientId: this.finishData.clientId,
          },
          pagination: {
            take: 500,
            skip: 0,
          },
        };
        this.$apollo
          .query({
            query: gql`
              ${query}
            `,
            variables: variables,
            fetchPolicy: 'no-cache',
          })
          .then(data => {
            const newCampaignList = [];
            data.data.campaignApplyResponseList.items.forEach(e => {
              newCampaignList.push({
                id: e.campaign.id,
                name: e.campaign.name,
              });
            });
            this.itemsData.contractCampaignList = newCampaignList;
          })
          .catch(async error => {
            this.setCopyErrorText(
              this.getCopyErrorTextView(query, variables, error.graphQLErrors),
            );
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            }
          });
      });
    },

    handleDeleteSignUpBonusApplication(id) {
      this.actionDeleteSignUpBonusApplication(id).then(deleteId => {
        const newArr = this.itemsData.signupBonusApplicationList.filter(
          x => x.id !== deleteId,
        );
        this.itemsData.signupBonusApplicationList = newArr;
      });
    },
    async getCampaignListDialog3(filter, pagination) {
      const variables = {
        filter: filter,
        // pagination: pagination,
        pagination: {
          skip: 0,
          take: 500,
        },
      };
      await this.$apollo
        .query({
          query: gql`
            ${CAMPAIGN_LIST}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then(data => {
          this.campaignList = data.data.campaignList.items;
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              CAMPAIGN_LIST,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
    fetchData() {
      this.fetchSignUpBonusList({ status: this.status, keyword: this.keyword });
    },
    async showPrivilegeDialog(id) {
      await this.fetchPrivilegeDialogData(id);
      this.currentId = id;
      this.dialog = 4;
    },
    fetchPrivilegeDialogData(id) {
      this.fetchSignUpBonusDetail(id);
    },
    getReceivedPaymentDifference
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .btn-custom {
    background-color: #f0f0f0 !important;
    border: 1px solid #13ace0;
    color: #13ace0;
  }
}
</style>

<style lang="scss">
h3 {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #004f8b;
}
.color-blu--ob {
  color: #0b6786 !important;
}
.gray {
  background-color: #f0f0f0;
  padding: 10px;
  width: 75%;
  margin-top: 10px;
  .v-btn__content {
    a {
      color: #13ace0 !important;
      font-size: 14px;
    }
    .mdi-close {
      color: #9e9e9e !important;
    }
  }
}
.v-btn--icon.v-size--default {
  width: 25px !important;
  height: 25px !important;
}
.btnCustome {
  background-color: #13ace0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  padding: 7px;
  // font-size: 22px;
  // padding: 7px 9px;
}
.btnCustomAd {
  background-color: #13ace0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  padding: 5px;
  // font-size: 22px;
  // padding: 7px 5px;
}
.mdi-delete {
  font-size: 18px !important;
}
.v-select-custom--label.v-input fieldset {
  height: 36px !important;
}
.select {
  .v-input__slot {
    fieldset {
      height: 36px !important;
    }
    .v-select__slot {
      margin-top: -5px !important;
      .v-label {
        font-weight: 500 !important;
        color: #000000 !important;
      }

      .v-input__append-inner {
        .theme--light.v-icon {
          color: #333333 !important;
        }
      }
    }
  }
}
.card1 {
  .v-text-field {
    .v-label {
      font-size: 14px !important;
      color: #9e9e9e !important;
      font-weight: 500 !important;
      &--active {
        font-size: 10px !important;
        color: #000 !important;
        font-weight: 600 !important;
      }
    }
  }
}
.v-input {
  .v-input__slot {
    .v-text-field__slot {
      input {
        color: #424242 !important;
      }
    }
  }
}
.v-select__selections {
  color: #424242 !important;
}
.btn-crm-primary {
  font-size: 10px !important;
}
.v-select__selections {
  .v-select__selection {
    color: #000000 !important;
    font-weight: 500;
  }
}
::v-deep {
  .v-select__slot {
    .v-label {
      color: #000000 !important;
      font-size: 10px !important;
      font-weight: 500 !important;
      &--active {
        color: #000000 !important;
        font-size: 10px !important;
        font-weight: 500 !important;
      }
    }
    .v-select__selections {
      color: red !important;
    }
  }
  .select {
    .v-application .v-input * {
    }
  }
  .v-btn__content {
    font-size: 10px !important;
  }
}
.label-title {
  color: #000000;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 0 !important;
}
.field {
  margin-top: 0;
  padding-top: 0;
}
.curency-input {
  margin: 0;
  padding: 0;
  .v-input__control {
    .v-input__slot {
      .v-text-field__slot {
        input {
          padding: 5px 0 !important;
          font-size: 14px !important;
          color: #424242 !important;
        }
      }
    }
  }
}
.custom-table {
  .v-data-table__wrapper {
    .v-data-table-header {
      tr {
        th {
          color: #757575 !important;
          font-weight: bold !important;
          font-size: 12px !important;
        }
      }
    }
    tbody > tr > td {
      color: #757575 !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      &:nth-child(1) {
        // max-width: 150px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      &:nth-child(2) {
        // max-width: 170px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      &:nth-child(4) {
        // max-width: 330px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
    }
  }
}
.custom-table-2 {
  .v-data-table__wrapper {
    .v-data-table-header {
      tr {
        th {
          color: #757575 !important;
          font-weight: bold !important;
          font-size: 12px !important;
        }
      }
    }
    tbody > tr > td {
      color: #757575 !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      &:nth-child(1) {
        // max-width: 150px !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      &:nth-child(2) {
        // max-width: 30px !important;
        // white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      &:nth-child(3) {
        // max-width: 30px !important;
        // white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      &:nth-child(4) {
        // max-width: 100px !important;
        // white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      &:nth-child(5) {
        // max-width: 200px !important;
        // white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
    }
  }
}
.header-main .btn-crm-primary {
  color: #1873d9 !important;
}
</style>
