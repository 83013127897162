<template>
  <div>
    <v-menu
      v-model="isShowDate"
      :close-on-content-click="false"
      min-width="auto"
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <ShowDate
          :type="attrTagInput.type"
          :date="dateRange"
          :attr="{ ...attrTagInput }"
          :icon="icon"
          :action="on"
          :hasBtnResetDatePicker="attrTagInput.hasBtnResetDatePicker"
          @reset="reset"
          :rules="rules"
        ></ShowDate>
      </template>

      <v-date-picker
        v-model="arrDates"
        no-title
        scrollable
        range
        :min="arrDates.length === 1 ? arrDates[0] : undefined"
        :first-day-of-week="0"
        :locale="$i18n.locale"
        class="v-date-picker-custom"
      >
        <v-spacer></v-spacer>
        <v-btn class="t-btn--gray" @click="close">
          {{ $t('buttons.cancel') }}
        </v-btn>
        <v-btn class="t-btn--prm" @click="save" :disabled="arrDates < 2">
          {{ $t('buttons.save') }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
// import moment from 'moment';
import ShowDate from '@/components/Input/datePicker/ShowDate.vue';

export default {
  name: 'DatePickerSelectFromTo',
  data() {
    return {
      datesTmp: [],
      isShowDate: false,
    };
  },
  components: {
    ShowDate,
  },
  watch: {
    dates: {
      immediate: true,
      handler (v) {
        this.datesTmp = v ? v.slice() : [] 
      }
    },
    isShowDate(v) {
      // when hidden we restore the data
      if (!v) {
        this.restore();
      }
    }
  },
  props: {
    dates: Array,
    attrTagInput: Object,
    funFormatTitle: {
      default: (val) => {
        return val;
      },
      type: Function,
    },
    icon: {
      default: false,
      Boolean,
    },
    rules: Array
  },
  computed: {
    arrDates: {
      get() {
        return this.datesTmp;
      },
      set(value) {
        this.datesTmp = value;
      },
    },
    dateRange() {
      return this.arrDates.join(' ~ ');
    },
  },
  methods: {
    restore () {
      this.arrDates = this.dates ? this.dates.slice() : [];
    },
    close() {
      this.isShowDate = false;
    },
    save() {
      this.$emit('setDates', this.arrDates);
      this.isShowDate = false;
    },
    reset() {
      this.$emit('setDates', []);
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
</style>