<template>
  <v-dialog
    v-model="visible"
    @click:outside="resetForm"
    content-class="px-8 py-4"
  >
    <v-form ref="form">
      <h2 class="text--big mb-4" style="font-size:28px ; color: #212121">
        契約特典編集
      </h2>
      <label style="color: #000000">{{ $t('commons.contractStatus') }}</label>
      <v-select
        hide-details
        dense
        style="color: #000000 !important"
        :items="statusOptions"
        v-model="detail.status"
        class="mb-4 sort-input"
      ></v-select>
      <label style="color: #000000">{{ $t('advertising.policies.policiesName') }}</label>
      <v-text-field
        style="color: #000000 !important"
        v-model="detail.name"
        :rules="[$rules.required, $rules.checkLenghInput(255)]"
        class="pa-0 mb-4"
      ></v-text-field>
      <h5 style="font-size:10px !important; color: #000000" class="mb-1">期間</h5>
      <DatePickerSelectRangeFromTo
        v-if="flag"
        :dates="dates"
        :attrTagInput="{
          readonly: true,
          type: 'text-field',
          color: '#000000',
          class: 'date-text pa-0 w-100',
          hasBtnResetDatePicker: true
        }"
        @setDates="(value) => {dates = value}"
        @reset="() => {dates = []}"
        :icon="true"
        :rules="[rules.isRequiredDatePickerRange]"
      ></DatePickerSelectRangeFromTo>
      <!-- abel="特典種類" -->
      <div class="my-5">
        <v-select
          hide-details
          dense
          style="color: #000000 !important"
          :items="typeList"
          v-model="detail.type"
          item-text="text"
          item-value="value"
          class="sort-input custom"
          label="特典種別"
        ></v-select>
      </div>
      <label style="color: #000000">{{ $t('advertising.contractSpecial.contractBenefits') }}</label>
      <v-textarea
        class="mt-2 rounded-lg"
        rows="5"
        outlined
        v-model="detail.description"
        :rules="[rules.isRequired, rules.maxLengthDescription]"
      ></v-textarea>
      <div class="elevation-4 rounded pa-5 mt-3">
        <v-checkbox
          hide-details
          v-model="isFillChecked"
          class="mr-3 mb-4 checkbox-custom"
          @change="checkFill"
          :label="$t('advertising.contractSpecial.fillChecbox')"
        ></v-checkbox>
        <label style="color: #000000 !important" :class="{ 'text--inactive': !isFillChecked }">{{
          $t('advertising.contractSpecial.numericalValue')
        }}</label>
        <v-text-field
          v-model="detail.amount"
          :disabled="!isFillChecked"
          :rules="[rules.maxInt]"
          class="pa-0 w-50"
        ></v-text-field>
      </div>
      <div class="elevation-4 rounded pa-5 mt-3">
        <v-checkbox
          hide-details
          v-model="isGenerateChecked"
          @change="checkGenerate"
          class="mr-3 checkbox-custom"
          :label="$t('advertising.contractSpecial.issueCodeAfterJoiningVS')"
        ></v-checkbox>
        <div class="d-flex align-center my-5">
          <div class="d-flex justify-start">
            <v-btn
              @click="getPromoCode"
              :disabled="!isGenerateChecked"
              class="t-btn--prm mr-4"
              >コードを生成</v-btn
            >
            <div>
              <h5 style="color: #000000 !important" :class="{ 'text--inactive': !isGenerateChecked }">
                特典コード
              </h5>
              <span style="color: #000000 !important"> {{ promoCode }} </span>
            </div>
          </div>
        </div>
        <div class="d-flex align-center my-5">
          <label style="color: #444444 !important" class="normal mr-3 mt-n5" :class="{ 'text--inactive': !isGenerateChecked }">{{
            $t('advertising.contractSpecial.entryValue')
          }}</label>
          <v-radio-group
            v-model="vsTypeSelected"
            :rules="isGenerateChecked && [rules.isRequired] || []"
            :disabled="!isGenerateChecked"
            row
          >
            <v-radio
              class="radio-custom"
              :label="$t('advertising.contractSpecial.discountMembership')"
              value="vsDiscount"
            ></v-radio>
            <v-radio
              class="radio-custom"
              :label="$t('advertising.contractSpecial.tokyuPointsGranted')"
              value="vsTvp"
            ></v-radio>
          </v-radio-group>
        </div>

        <label style="color: #000000" :class="{ 'text--inactive': !isGenerateChecked }">{{ $t('advertising.contractSpecial.numericalValue') }}</label>
        <v-text-field
          :value="
            (detail.vsPromoTVP !== 0 && detail.vsPromoTVP) ||
              (detail.vsPromoDiscount !== 0 && detail.vsPromoDiscount) ||
              null
          "
          @change="vsQuantity = $event"
          :rules="isGenerateChecked && [rules.isRequired, rules.maxInt] || []"
          :disabled="!isGenerateChecked"
          class="pa-0 w-50"
        ></v-text-field>
        <v-checkbox
              hide-details
              v-model="canUseInMemberWeb"
              class="mr-3 mb-4 checkbox-custom"
              label="会員WEBで利用可能"
            />
            <v-checkbox
              hide-details
              v-model="canUseInPromoUrl"
              class="mr-3 mb-4 checkbox-custom"
              label="プロモーションURLから利用可能"
            />
      </div>
      <div class="text-right mt-5">
        <v-btn @click="resetForm" class="t-btn--red-dark">
          <v-icon>mdi-close</v-icon>
          {{ $t('buttons.close') }}
        </v-btn>
        <v-btn @click="submitForm" class="t-btn--prm ml-4">{{
          $t('buttons.save')
        }}</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>
<script>
import { nextOrPrevDay } from '@/utils/dateUtil';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { validDatePickerRange } from '@/utils/validate';
import { MAX_INT } from '@/constants/enum';
import DatePickerSelectRangeFromTo from '@/components/Input/datePicker/DatePickerSelectRangeFromTo.vue';

export default {
  name: 'ContractSpecialDialog',
  components: {
    DatePickerSelectRangeFromTo,
  },
  data() {
    return {
      row: null,
      flag: false,
      dates: [],
      // isShowDate: false,
      statusOptions: [
        {
          text: '対応中',
          value: 'Active',
        },
        {
          text: '対応済',
          value: 'Ended',
        },
      ],
      statusSelected: 'Active',
      typeList: [
        {
          text: '値引き',
          value: 'Discount',
        },
        {
          text: 'ポイント付与',
          value: 'Point',
        },
        {
          text: '商品券',
          value: 'Voucher',
        },
        {
          text: 'マイル付与',
          value: 'Mile',
        },
      ],
      isFillChecked: true,
      isGenerateChecked: true,
      vsTypeSelected: null,
      promoCode: null,
      vsQuantity: null,
      canUseInMemberWeb: true,
      canUseInPromoUrl: false,
      rules: {
        isRequired: value => !!value || this.$t('rules.isRequired'),
        isRequiredDatePickerRange: value => validDatePickerRange(value) || this.$t('rules.isRequired'),
        // maxLengthTitle:
        //   (v) => CheckLenghInput(v, 255) || this.$t('rules.maximumNCharacter', { value: 255 }),
          // maxLengthTitle: value =>
          // value?.length < 255 || this.$t('rules.isInvalid'),
        maxLengthDescription: value =>
          value?.length < 2000 || this.$t('rules.isInvalid'),
        maxInt: v => v < MAX_INT || this.$t('rules.isInvalid'),
      },
    };
  },
  props: {
    detail: Object,
  },
  computed: {
    ...mapGetters(['signupBonusVsPromoCode']),
    // dateRangeText: {
    //   get() {
    //     return this.dates.join(' ~ ');
    //   },
    //   set() {
    //     return this.dates.join(' ~ ');
    //   },
    // },
    visible() {
      return true;
    },
  },
  mounted() {
    this.dates = [this.detail.startDate, this.detail.endDate];
    this.isGenerateChecked = this.detail.vsPromoCode !== null || this.detail.vsPromoTVP !== 0 || this.detail.vsPromoDiscount !== 0;
    this.isFillChecked = this.detail.amount !== null;
    this.promoCode = this.detail.vsPromoCode;
    this.vsTypeSelected =
      (this.detail.vsPromoTVP !== 0 && 'vsTvp') ||
      (this.detail.vsPromoDiscount !== 0 && 'vsDiscount') ||
      null;
    this.vsQuantity = (this.detail.vsPromoTVP !== 0 && this.detail.vsPromoTVP) ||
      (this.detail.vsPromoDiscount !== 0 && this.detail.vsPromoDiscount) ||
      null;
    this.canUseInMemberWeb = this.detail.canUseInMemberWeb ?? true; // default true. seet TO2020-1139
    this.canUseInPromoUrl = this.detail.canUseInPromoUrl ?? false;
    this.flag = true
  },
  methods: {
    ...mapActions(['generateSignupBonusVsPromoCode', 'updateSignupBonus', 'fetchSignUpBonusDetail', 'fetchSignUpBonusList']),
    ...mapMutations(['setSignupBonusVsPromoCode', 'setPermissionUser', 'setRoleAdminUser']),
    // 'setSignupBonusVsPromoCode'
    nextOrPrevDay,
    async getPromoCode() {
      await this.generateSignupBonusVsPromoCode();
      this.promoCode = this.signupBonusVsPromoCode;
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        const vsDiscount =
          (this.vsTypeSelected === 'vsDiscount' && this.vsQuantity) || 0;
        const vsTvp = (this.vsTypeSelected === 'vsTvp' && this.vsQuantity) || 0;
        const sendData = {
          id: this.detail.id,
          status: this.detail.status,
          name: this.detail.name,
          startDate: this.dates[0],
          endDate: this.dates[1],
          type: this.detail.type,
          description: this.detail.description,
          amount: this.detail.amount,
          code: this.promoCode,
          vsTvp,
          vsDiscount,
          canUseInMemberWeb: this.canUseInMemberWeb,
          canUseInPromoUrl: this.canUseInPromoUrl,
        };
        await this.updateSignupBonus(sendData)
        this.fetchSignUpBonusDetail(this.detail.id)
        // this.fetchSignUpBonusList({status: null, keyword: null })
        this.$emit('fetchData')
        this.$emit('close')
      }
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.$emit('close');
    },
    checkFill(v) {
      if (!v) {
        this.detail.amount = null;
      }
    },
    checkGenerate(v) {
      if (!v) {
        this.setSignupBonusVsPromoCode(null);
        this.promoCode = null;
        this.vsTypeSelected = null;
        this.vsQuantity = null;
        this.detail.vsPromoDiscount = null;
        this.detail.vsPromoTVP = null
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .mdi-calendar-month{
    margin: 0px 5px 15px 5px;
  }
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 600px;
  }
  .custom{
    .v-select__selection{
      color: #000000 !important
    }
  }
  .radio-custom {
    .v-label{
      color: #444444
    }
  }
  .checkbox-custom{
    .v-label{
      color: #444444
    }
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text {
      max-width: 250px !important;
      input {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 10px;
  }
}
</style>