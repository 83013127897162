<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="resetForm">
      <v-form ref="form">
        <h1>契約時書類アップロード</h1>
        <template>
          <v-file-input
            multiple
            label="契約時書類アップロード(multipl upload)"
            filled
            @change="getUploadURL($event)"
            prepend-icon="mdi-camera"
            class="mt-2"
            :rules="uploadUrlRules"
            :messages="[$messages.fileLimit({multiple: true, size: '100MB'})]"
          ></v-file-input>
        </template>
        <div class="d-flex justify-end">
          <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <v-btn class="t-btn--prm" :disabled="disableBtn" to @click="save"> 保存 </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { GENERATE_UPLOAD_URL } from '@/api/graphql/contract/contractDetail/sp_ep/UploadDocuments/uploadDocuments.js';
import { mapActions, mapMutations } from 'vuex';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import axios from 'axios';
import gql from 'graphql-tag'

export default {
  name: 'UploadDocuments',
  data() {
    return {
      disableBtn: false,
      arrFile: [],
      file: null,
      uploadKey: '',
      urlUpload: '',
      uploadUrlRules: [
        (v) => !!v || 'ファイルを選択して下さい'
      ],
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  mounted() {
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'actionContractTimeInformation',
      'createContractAttachmentUpload',
      'createPointExchange',
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setErrorDialog',
      'setFileName',
      'setFileKey',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    resetForm() {
      this.$refs.form.reset();
    },
    getUploadURL(event) {
      this.arrFile = event
    },
    async uploadURL() {
      for (let i = 0; i < this.arrFile.length; i++) {
        const variables = {
            contentType: this.arrFile[i].type,
            contentLength: this.arrFile[i].size,
            fileName: this.arrFile[i].name
          }
        await this.$apollo
        .mutate({
          mutation: gql`${GENERATE_UPLOAD_URL}`,
          variables: variables,
        })
        .then(async (data) => {
          this.uploadKey = data.data.generateUploadUrl.fileUrl;
          this.urlUpload = data.data.generateUploadUrl.uploadUrl;
          await this.setFileName(this.arrFile[i].name);
          await this.setFileKey(this.uploadKey);
          await axios.put(
            this.urlUpload,
            this.arrFile[i],
            {
              headers: {
                'Content-Type': this.arrFile[i].type,
              },
            },
          );
        
          await this.actionContractTimeInformation();
          await this.createContractAttachmentUpload();
          await this.$emit('uploadAdd');
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(GENERATE_UPLOAD_URL, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        });
      }
    },

     async save() {
       this.disableBtn = true
      if (this.$refs.form.validate()) {
        await this.uploadURL()
        this.$emit('uploadAdd');
        this.$emit('close');
      }
    },

    closeDialog() {
      this.$emit('close');
      this.resetForm();
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}

::v-deep {
  .v-dialog {
    width: 480px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .theme--light.v-icon {
    color: #757575 !important;
  }
  .theme--light.v-label {
    color: #606060 !important;
    font-size: 15px !important;
  }
  .v-btn {
    height: 32px !important;
  }
  .t-btn--red-dark {
    min-width: 76px !important;
    .mdi-close {
      color: #fff !important;
    }
  }
  .t-btn--prm {
    min-width: 68px !important;
  }
}
</style>
