<template>
  <v-dialog v-model="dialogAdv" @click:outside="closeDialog" :content-class="className + ' pa-5'">
    <v-form ref="formCreateAdvert">
      <h2 class="text--big mb-4">{{ idPolicy ? $t('buttons.advertisingPolicyEdit') : $t('advertising.buttonAdd')}}</h2>
      <label>{{ $t('commons.contractStatus') }}</label>
      <v-select
        :items="statusAdvert"
        v-model="statusAdvertSelected"
        dense
        :rules="[statusAdvertBlank]"
        item-text="name"
        item-value="id"
        class="mb-4"
      ></v-select>

      <v-row>
        <v-col cols="12" md="6">
          <label>{{ $t('advertising.policies.advertisingName') }}</label>
          <v-text-field
            v-model="nameAdvert"
            class="pa-0 w-100 mb-4"
            :rules="[$rules.required, $rules.checkLenghInput(255)]"
          ></v-text-field>

          <label>{{ $t('advertising.policies.groupName') }}</label>
          <v-combobox
            dense
            :items="getEnumCampaignGroupList"
            class="mb-4"
            multiple
            persistent-hint
            :rules="[groupSelectedBlank]"
            v-model="groupSelected"
          ></v-combobox>

          <!-- Date picker -->
          <label style="font-size: 10px !important;color: #000000 !important;">{{ $t('advertising.stage') }}</label>
          <DatePickerSelectRangeFromTo
            :dates="dates"
            :attrTagInput="{
              readonly: true,
              type: 'text-field',
              color: '#000000',
              class: 'date-text pa-0 w-50',
              hasBtnResetDatePicker: true
            }"
            @setDates="(value) => {dates = value}"
            @reset="() => {dates = []}"
            :icon="true"
            :rules="[rules.isRequiredDatePickerRange]"
          ></DatePickerSelectRangeFromTo>

          <label>{{ $t('advertising.policies.implemenCost') }}</label>
          <tvos-int-input v-model="implemenCost" jpy class="mb-9" />

          <!-- TARGET group -->
          <label>{{ $t('commons.target') }}</label>
          <div class="d-flex mt-n3 flex-wrap mb-4">
            <v-checkbox
              v-for="item in listTarget"
              :key="item.value"
              v-model="targetSelected"
              :value="item.value"
              :label="item.label"
              hide-details
              class="mr-3"
            ></v-checkbox>
          </div>
          <!-- End - TARGET group -->

          <!-- PURPOSE group -->
          <label>{{ $t('commons.purpose') }}</label>
          <div class="d-flex mt-n3 flex-wrap mb-4">
            <v-checkbox
              v-for="item in listPurpose"
              :key="item.value"
              v-model="purposeSelected"
              :value="item.value"
              :label="item.label"
              hide-details
              class="mr-3"
            ></v-checkbox>
          </div>
          <!-- End - PURPOSE group-->

          <!-- METHOD group -->
          <label>{{ $t('advertising.policies.method') }}</label>
          <div class="d-flex mt-n3 flex-wrap mb-9">
            <v-checkbox
              v-for="item in listMethod"
              v-model="methodSelected"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              hide-details
              class="mr-3"
            ></v-checkbox>
          </div>
          <!-- End - METHOD group-->

          <label>{{ $t('advertising.policies.description') }}</label>
          <v-textarea
            class="mt-2 rounded-lg"
            rows="5"
            outlined
            v-model="textDescription"
            :rules="[$rules.checkTextAreaLength()]"
          ></v-textarea>
        </v-col>

        <v-col cols="12" md="6" class="pl-10">
          <!-- List input upload file -->
          <div
            class="d-flex justify-space-between mb-3"
          >
            <v-file-input
              v-model="fileAttach1"
              :rules="[uploadFileRules, fileType]"
              accept=".pdf"
              prepend-icon="mdi-file-upload"
              filled
              clearable
              @change="handleFileUpload($event, 'file1')"
            ></v-file-input>
            <v-chip
              @click="previewFileAtt(1)"
              label
              color="var(--crm_primaryr_13ace0)"
              class="mt-4 previewFile"
              >{{ $t('commons.browsing') }}</v-chip
            >
          </div>
          <div
            class="d-flex justify-space-between mb-3"
          >
            <v-file-input
              v-model="fileAttach2"
              :rules="[uploadFileRules, fileType]"
              accept=".pdf"
              clearable
              prepend-icon="mdi-file-upload"
              filled
              @change="handleFileUpload($event, 'file2')"
            ></v-file-input>
            <v-chip
              @click="previewFileAtt(2)"
              label
              color="var(--crm_primaryr_13ace0)"
              class="mt-4 previewFile"
              >{{ $t('commons.browsing') }}</v-chip
            >
          </div>
          <div
            class="d-flex justify-space-between mb-3"
          >
            <v-file-input
              v-model="fileAttach3"
              :rules="[uploadFileRules, fileType]"
              accept=".pdf"
              prepend-icon="mdi-file-upload"
              filled
              clearable
              @change="handleFileUpload($event, 'file3')"
            ></v-file-input>
            <v-chip
              @click="previewFileAtt(3)"
              label
              color="var(--crm_primaryr_13ace0)"
              class="mt-4 previewFile"
              >{{ $t('commons.browsing') }}</v-chip
            >
          </div>
          <!-- End - List input upload file -->

          <!-- Target response -->
          <label>
            {{ $t('advertising.policies.selectTargetResponseMedia') }}
          </label>
          <div class="elevation-3 mt-2 pa-4 pb-3">
            <div class="mb-4">
              <div class="d-flex flex-wrap">
                <v-checkbox
                  v-for="(input,index) in getEnumCampaignMediaTypeList"
                  v-model="mediaTypeSelected"
                  :key="input.id"
                  :value="parseInt(input.id)"
                  :label="input.name"
                  class="mr-3"
                  :rules="validateCheckbox"
                  :hide-details="!isMediaType(index)"
                ></v-checkbox>
                
              </div>
            </div>
          </div>
          <!-- End - Target response -->
        </v-col>
      </v-row>

      <div class="text-right mt-5">
        <v-btn @click="resetForm" class="t-btn--red-dark">
          <v-icon>mdi-close</v-icon>
          {{ $t('buttons.close') }}
        </v-btn>
        <v-btn @click="submitForm" class="t-btn--prm ml-4">{{
          $t('buttons.save')
        }}</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ENUM_CAMPAIGN_GROUP_LIST, ENUM_CAMPAIGN_MEDIA_TYPE_LIST, CREATE_CAMPAIGN_ADV_POLICIES, GENERATE_UPLOAD_URL } from '@/api/graphql/advertising/advertising';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
// import { CheckLenghInput } from '@/utils/validate';
import axios from 'axios'
import gql from 'graphql-tag'
import { validDatePickerRange } from '@/utils/validate';
import DatePickerSelectRangeFromTo from '@/components/Input/datePicker/DatePickerSelectRangeFromTo.vue';

export default {
  name: 'AdvertisingPoliciesDialog',
  components: {
    DatePickerSelectRangeFromTo,
  },
  data() {
    return {
      isValid: true,
      isShowDate: false,
      uploadFileRules (v) { 
        return (!v || v.size <= 10000000) || '10MBを超えることはできません'
      },
      fileType (v) {
        return (!v || (v.type.includes('application/pdf'))) || '指定されたファイルの拡張子はサポートされていません'
      },
      statusAdvertBlank (v) {
        return !!v || '必須項目です。'
      },
      // nameAdvertBlank: [
      //  (v) => !!v || '必須項目です。',
      //  (v) => CheckLenghInput(v, 255) || this.$t('rules.maximumNCharacter', { value: 255 })
      // ],
      rules: {
        isRequiredDatePickerRange: value => validDatePickerRange(value) || this.$t('rules.isRequired'),
      },
      groupSelectedBlank (v) {
        return v.length > 0 || '必須項目です。'
      },
      dateRangerBlank (v) {
        return v.length > 0 ||  '必須項目です。'
      },
      implemenCost: 0,
      dates: [],
      targetSelected: [],
      listTarget: [
        {
          value: 'target20s',
          label: '20' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target30s',
          label: '30' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target40s',
          label: '40' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target50s',
          label: '50' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target60s',
          label: '60' + this.$t('commons.ageUnit'),
        },
        {
          value: 'target70s',
          label: '70' + this.$t('advertising.policies.overYearsOld'),
        },
        {
          value: 'targetMale',
          label: this.$t('commons.male'),
        },
        {
          value: 'targetFemale',
          label: this.$t('commons.female'),
        },
      ],
      purposeSelected: [],
      listPurpose: [
        {
          value: 'purposeRecognition',
          label: this.$t('advertising.policies.awarenessExpansion'),
        },
        {
          value: 'purposePersuade',
          label: this.$t('advertising.policies.harvestingPolicy'),
        },
        {
          value: 'purposePromote',
          label: this.$t('advertising.policies.promotionOfUse'),
        },
      ],
      methodSelected: [],
      listMethod: [
        {
          value: 'methodDm',
          label: 'DM',
        },
        {
          value: 'methodEmail',
          label: this.$t('advertising.policies.emailNewsletter'),
        },
        {
          value: 'methodBillboard',
          label: this.$t('advertising.policies.signboard'),
        },
        {
          value: 'methodTv',
          label: 'TV',
        },
        {
          value: 'methodSns',
          label: 'SNS',
        },
        {
          value: 'methodWeb',
          label: 'WEB',
        },
      ],
      textDescription: '',
      listFileUpload: [
        {
          nameDefault: 'xxx.pdf',
          file: null,
          status: true,
        },
        {
          nameDefault: null,
          file: null,
          status: false,
        },
        {
          nameDefault: null,
          file: null,
          status: false,
        },
      ],

      statusAdvert: [
        {
          id: 'Active', 
          name: '対応中'
        },
        {
          id: 'Ended', 
          name: '終了'
        },
      ],
      fileAttach1: null,
      fileAttach2: null,
      fileAttach3: null,
      previewFile1: null,
      previewFile2: null,
      previewFile3: null,
      statusAdvertSelected: null,
      mediaTypeSelected:[],
      nameAdvert: '',
      groupSelected: []
    };
  },
  props: {
    visible: Boolean,
    className: {
      type: String,
      default: '',
    },
    idPolicy: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
     ...mapGetters([
      'getEnumCampaignGroupList',
      'getEnumCampaignMediaTypeList'
     
    ]),
    dialogAdv: {
      get () {
        return this.visible
      },
      set (value) {
        if(!value) {
          this.$emit('close');
        }
      }
    },
    // dateRangeText: {
    //   get() {
    //     return this.dates.join(' ~ ');
    //   },
    //   set() {
    //     this.dates.join(' ~ ');
    //   },
    // },
    validateCheckbox () {
      return [this.mediaTypeSelected.length > 0 || "対象反響媒体選択を選択してください"]
    }
  },
  created () {
    this.getAdvertisingPolicyGroup()
    this.getDataEnumCampaignMediaTypeList()
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setAlertError',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setEnumCampaignGroupList',
      'setCampaignMediaTypeList',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),

    formatDate (event) {
      if (new Date(event[0]).getTime() - new Date(event[1]).getTime() > 0) {
        this.dates = [event[1],event[0]]
      }
    },

    closeDialog() {
      this.$emit('close');
      this.$refs.formCreateAdvert.resetValidation()
      this.$refs.formCreateAdvert.reset();
      this.dates = [];
      this.implemenCost = 0
    },
    handleFileUpload(obj, name = null) {
     if (obj) {
        if (name === 'file1') {
          this.previewFile1 = URL.createObjectURL(obj)
        }
        if (name === 'file2') {
          this.previewFile2 = URL.createObjectURL(obj)
        }
        if (name === 'file3') {
          this.previewFile3 = URL.createObjectURL(obj)
        }
      } else {
        if (name === 'file1') {
          this.previewFile1 = null
        }
        if (name === 'file2') {
          this.previewFile2 = null
        }
        if (name === 'file3') {
          this.previewFile3 = null
        }
      }
    },
    isMediaType (index) {
      return this.getEnumCampaignMediaTypeList.length -1 === index
    },
    async submitForm() {
      this.$refs.formCreateAdvert.validate();
      if (this.$refs.formCreateAdvert.validate()) {
        // process data create
        let sttUploadFile = true
        const dataCreate = {
          status: this.statusAdvertSelected,
          name: this.nameAdvert,
          groups: this.groupSelected,
          startDate: this.dates[0],
          endDate: this.dates[1],
          budget: parseInt(this.implemenCost),
          target20s: false,
          target30s: false,
          target40s: false,
          target50s: false,
          target60s: false,
          target70s: false,
          targetFemale: false,
          targetMale: false,
          purposeRecognition: false,
          purposePromote: false,
          purposePersuade: false,
          methodDm: false,
          methodEmail: false,
          methodBillboard: false,
          methodTv: false,
          methodSns: false,
          methodWeb: false,
          mediaTypeIds: this.mediaTypeSelected
        }
        if (this.textDescription) {
          dataCreate.note = this.textDescription
        }
        if (this.fileAttach1) {
          const fileURl1 =  await this.uploadURL(this.fileAttach1)
          if (fileURl1) {
            await this.actionPutPdf(this.fileAttach1, fileURl1.uploadUrl)
            dataCreate.attachment1 = fileURl1.fileUrl
          } else {
            sttUploadFile = false
          } 
        }
        if (this.fileAttach2) {
          const fileURl2 =  await this.uploadURL(this.fileAttach2)
           if (fileURl2) {
            await this.actionPutPdf(this.fileAttach2, fileURl2.uploadUrl)
            dataCreate.attachment2 = fileURl2.fileUrl
          } else {
            sttUploadFile = false
          } 
        }
        if (this.fileAttach3) {
          const fileURl3 =  await this.uploadURL(this.fileAttach3)
          if (fileURl3) {
            await this.actionPutPdf(this.fileAttach3, fileURl3.uploadUrl)
            dataCreate.attachment3 = fileURl3.fileUrl
          } else {
            sttUploadFile = false
          } 
        }
        if (this.targetSelected.length > 0) {
          this.targetSelected.map((ite) => {
            dataCreate[ite] = true
            return ite
          })
        }
        if (this.purposeSelected.length > 0) {
          this.purposeSelected.map((ite) => {
            dataCreate[ite] = true
            return ite
          })
        }
        if (this.methodSelected.length > 0) {
          this.methodSelected.map((ite) => {
            dataCreate[ite] = true
            return ite
          })
        }
        if (sttUploadFile) {
          this.setLoadingOverlayShow()
          const variables = {
              ...dataCreate
            }
          await this.$apollo.mutate({
            mutation: gql`${CREATE_CAMPAIGN_ADV_POLICIES}`,
            variables: variables,
            fetchPolicy: 'no-cache'
          }).then((data) => {
            this.setLoadingOverlayHide()
            if (data.data.createCampaign.id) {
              this.resetForm()
              this.$emit('fetchList');
              this.setAlertSuccess('作成しました。')
            }
          }).catch(async (error) => {
            this.setLoadingOverlayHide()
            this.setCopyErrorText(this.getCopyErrorTextView(CREATE_CAMPAIGN_ADV_POLICIES, variables, error.graphQLErrors))
            const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true })
            } else {
              this.setAlertError('新しいものを作成できません')
            }
          })
        }
      } else {
        this.isValid = false
      }
    },
    previewFileAtt (ind) {
      const preview = [this.previewFile1, this.previewFile2, this.previewFile3]
      const file = preview[ind - 1];
      if (file) {
        window.open(file)
        return true
      } else {
        return false
      }
    },
    async uploadURL(file) {
      // if (v && v.size < 10485760) {
      const variables = {
        contentType: file.type,
        contentLength: file.size,
        fileName: file.name
      }
      try  {
        this.setLoadingOverlayShow()
        const fileURl = await this.$apollo
          .mutate({
            mutation: gql`${GENERATE_UPLOAD_URL}`,
            variables: variables,
          })
          if (fileURl.data.generateUploadUrl) {
            this.setLoadingOverlayHide()
            return fileURl.data.generateUploadUrl
          }
          return ''
      } catch (error) {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(GENERATE_UPLOAD_URL, variables, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        } else {
          this.setAlertError('新しいものを作成できません')
        }
        return ''
      }
    },
    
    async actionPutPdf (file, url) {
      this.setLoadingOverlayShow()
      await axios.put(
        url,
        file,
        {
          headers: {
            'Content-Type': file.type,
          },
        },
      )
      this.setLoadingOverlayHide()
    },

    resetForm() {
      this.$refs.formCreateAdvert.resetValidation()
      this.$refs.formCreateAdvert.reset();
      this.dates = [];
      this.implemenCost = 0
      this.$emit('close');
    },
    async getAdvertisingPolicyGroup () {
      await this.$apollo.query({
        query: gql`${ENUM_CAMPAIGN_GROUP_LIST}`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (data.data.enumCampaignGroupList.length > 0) {
          const dataEnumCampaignGroupList = data.data.enumCampaignGroupList.map((item) => item.name)
          this.setEnumCampaignGroupList({ enumCampaignGroupList: dataEnumCampaignGroupList })
        }
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(ENUM_CAMPAIGN_GROUP_LIST, null, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
      })
    },
    async getDataEnumCampaignMediaTypeList () {
      await this.$apollo.query({
        query: gql`${ENUM_CAMPAIGN_MEDIA_TYPE_LIST}`,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (data.data.enumCampaignMediaTypeList.length > 0) {
          // #41068
          const dataFilter = data.data.enumCampaignMediaTypeList.filter((item) => item.id !== 5 && item.id !== 6 && item.id !== 8 && item.id !== 12 && item.id !== 14)
          this.setCampaignMediaTypeList({ enumCampaignMediaTypeList: dataFilter })
        }
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(ENUM_CAMPAIGN_MEDIA_TYPE_LIST, null, error.graphQLErrors))
        const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 1100px;
  }
  .v-input:not(.w-100):not(.v-input--checkbox):not(.v-textarea):not(.v-file-input),
  .currency-input input {
    width: 220px;
    max-width: 220px;
  }
  .date-text input {
    font-size: 12px;
  }
  .v-input--checkbox label {
    white-space: nowrap;
  }
  .v-file-input {
    max-width: 415px;
  }
  .v-chip {
    color: var(--text_white);
  }
  .--child {
    height: 70vh
  }
  .v-input__slot{
    margin-bottom: 0px !important;
    .v-text-field__slot{
      input{
        padding-bottom: 0 !important;
      }
    }
  }
}
label {
  font-size: 10px;
}
.previewFile {
  cursor: pointer;
}
</style>