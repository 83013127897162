<template>
  <!-- <div> -->
  <tvos-int-input
    jpy
    :class="className ? className : ''"
    :value="value"
    @input="$emit('input', $event)"
    :placeholder="placeholder ? placeholder : ''"
    :rules="rules"
    :disabled="!checkPerUser"
    :readonly="readonly"
  />
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
export default {
  name: 'CurrencyInput',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
    };
  },
  props: {
    value: [Number, String],
    className: String,
    placeholder: String,
    rules: [Object, Array],
    readonly: Boolean
  }
};
</script>

<style lang="scss" scoped></style>
